<template>
  <member-setting-container>
    <!-- Notifications list -->
    <b-card>
      <div class="d-flex flex-wrap justify-content-between my-2">
        <h4>
          {{ $t('backoffice.notifications.title') }}
        </h4>
        <b-button v-if="appsWithNotifications"
          variant="primary"
          @click="handleDismissAll()"
        >
          {{ $t('notifications.mark-all-as-read') }}
        </b-button>
      </div>
      <base-vertical-container
        :items="items"
        :total="total"
        :placeholder-message="$t('notifications.empty-notifications')"
        :per-page="perPage"
        :loading="loading"
        :loading-next-page="isLoadingNextPage"
        class="m-auto notifications"
        @load-next="handleLoadNextPage"
      >
        <template #item="{ item: notification }">
          <notification
            class="w-100 border py-1 px-2 bg-white mb-2"
            :notification="notification"
            @dismissed="handleDismissed"
          />
        </template>
      </base-vertical-container>
    </b-card>

    <!-- Preferences -->
    <b-card>
      <div class="d-flex flex-wrap justify-content-between my-2">
        <h4>
          {{ $t('backoffice.notifications.preferences') }}
        </h4>
        <b-button v-if="appsWithNotifications && appsWithNotifications.length > 0" variant="primary" @click="editItem()">
          {{ $t('form.actions.save') }}
        </b-button>
      </div>

      <!-- Loading -->
      <b-spinner
        v-if="isLoading"
        type="grow"
        small
        class=" text-primary d-block m-auto"
      />
      <!-- Apps with notifications settings -->
      <b-row v-else-if="appsWithNotifications && appsWithNotifications.length > 0" class="mt-2">
        <b-col
          v-for="(app, index) in appsWithNotifications"
          :key="index"
          cols="12"
          md="6"
          lg="4"
          xl="3"
          class="mb-2"
        >
          <b-card>
            <div class="d-flex flex-wrap justify-content-between">
              <h4 class="model-type">
                {{ app.addon[locale] }}
              </h4>
              <div>
                <div class="d-flex">
                  <b-checkbox v-model="appsWithNotifications[index].settings.push" /> Push
                  <b-checkbox v-model="appsWithNotifications[index].settings.emails" class="ml-1" /> Email
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-else class="horizontal-placeholder flex-column">
        <b-col cols="12">
          <img :src="placeholder" class="m-0 d-block mx-auto">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("addons.no-addons") }}
          </p>
        </b-col>
      </b-row>

      <!-- Push notifications -->
      <push-notifications-checkbox 
        class="pb-1 px-1 pt-2 border-top" 
      />
    </b-card>

  </member-setting-container>
</template>

<script>
import Vue from 'vue';
import Service from '@/config/service-identifiers';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import MemberSettingContainer from '@/views/myself/components/MemberSettingContainer.vue';
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';
import BaseVerticalContainer from '@core/components/containers/base/BaseVerticalContainer.vue';
import PushNotificationsCheckbox from '@core/components/notifications/PushNotificationsCheckbox.vue';
import ItemsListMixin from '@core/components/containers/mixins/ItemsListMixin';
import Notification from '@core/components/notifications/NotificationItem.vue';

import {
  NOTIFICATIONS_ACTIONS, NOTIFICATIONS_GETTERS, NOTIFICATIONS_MUTATIONS,
} from '@/store/notifications/notifications-store-constants';

export default {
  name: 'Notifications',
  components: {
    MemberSettingContainer,
    BaseVerticalContainer,
    PushNotificationsCheckbox,
    Notification
  },
  mixins: [ToastNotificationsMixin, ItemsListMixin],
  props: {
    perPage: {
      type: Number,
      default: 8,
    },
  },
  data() {
    return {
      fetchedTotal: 1,
      isLoading: false,
      appsWithNotifications: [],
      operativeApps: ['subcommunity', 'debate', 'event', 'forum', 'post'],
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    allPermissions() {
      return this.$store.getters.communityMembers.unpaginated[0].settings;
    },
    enabledApps() {
      return this.$store.getters.installedApps.data?.filter((item) => operativeApps.includes(item.addonSingular));
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    placeholder() {
      return Placeholder;
    },
    total() {
      return this.fetchedTotal;
    },
    forceReload() {
      return this.$store.getters[NOTIFICATIONS_GETTERS.forceReload];
    },
  },
  async created() {
    await this.getInstalledApps();
    await this.getSettings();
  },
  watch: {
    async forceReload(forceReload) {
      if (forceReload) {
        this.loading = true;
        this.currentPage = 0;
        this.items = [];
        await this.handleLoadNextPage();
        this.loading = false;
        this.$store.commit(NOTIFICATIONS_MUTATIONS.setForceReload, false);
      }
    },
  },
  methods: {
    async editItem() {
      const settings = {};
      this.appsWithNotifications.map((item) => {
        settings[item.key] = item.settings;
      });
      try {
        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'members',
            customName: 'communityMembers',
            requestConfig: {
              settings,
              memberKey: this.loggedMember.key,
            },
          },
        });
        this.notifySuccess(this.$t('backoffice.notifications.messages.edit-success'));
      } catch {
        this.notifyError(this.$t('backoffice.notifications.messages.edit-error'));
      }
    },
    async getInstalledApps() {
      await this.$store.dispatch('getInstalledApps');
    },
    async getSettings() {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'communityMembers',
        customName: 'communityMembers',
        forceAPICall: true,
        requestConfig: {
          username: this.loggedMember.username,
          isForProfile: true,
          getMinimalData: 1
        },
      });

      // Aquí creamos un array de notificaciones con las apps que tienen notificaciones
      this.appsWithNotifications = this.$store.getters.installedApps.data?.filter((item) => this.operativeApps.includes(item.addonSingular));
      // console.log('appsWithNotifications', this.appsWithNotifications);

      if (this.allPermissions === null) {
        this.appsWithNotifications.forEach((item) => item.settings = { 
          push: true, 
          emails: true 
        });
      } else {
        this.appsWithNotifications.forEach((item) => {
          if (this.allPermissions[item.key]) {
            item.settings = { 
              push: this.allPermissions[item.key]?.push, 
              emails: this.allPermissions[item.key]?.emails 
            };
          }
        });
      }
      this.isLoading = false;
    },
    handleDismissed(notification) {
      const dismissedNotificationIndex = this.items.findIndex(({ key }) => key === notification.key);
      if (dismissedNotificationIndex >= 0) {
        Vue.set(
          this.items,
          dismissedNotificationIndex,
          {
            ...this.items[dismissedNotificationIndex],
            isRead: 1,
          },
        );
        const notificationArray = this.$store.state.notifications.notifications
        const newNotificationArray =  notificationArray.filter((_, index) => index !== dismissedNotificationIndex);
        this.$store.dispatch(NOTIFICATIONS_ACTIONS.allNotificationsDismissed);
        this.$store.dispatch(NOTIFICATIONS_MUTATIONS.SET_NOTIFICATIONS, newNotificationArray);
      }
    },
    async loadPage({ page, perPage }) {
      try {
        const response = await this.$store.$service[Service.BackendClient].get('/notifications', {
          params: {
            communityKey: this.$store.getters.currentCollective.key,
            isDescendentOrdered: true,
            page,
            count: perPage,
          },
        });
        this.fetchedTotal = response.data.meta.total;
        return response.data.data;
      } catch {
        this.notifyError('Hubo un error al cargar las notificaciones');
        return [];
      }
    },
    async handleDismissAll() {
      try {
        await this.$store.$service[Service.BackendClient]
          .post('/markAsReadAllNotifications');

        this.$store.dispatch(NOTIFICATIONS_ACTIONS.allNotificationsDismissed);
        this.items.forEach((notification) => Vue.set(notification, 'isRead', 1));
      } catch {
        this.notifyError(this.$tc('notifications.message.mark-as-read-error', 2));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.model-type {
  width: 100%;
}
.model-type h4:first-letter {
  text-transform: uppercase;
}
.pointer-icon {
  cursor: pointer;
}
</style>
